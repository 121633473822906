<template>
    <div>
      <appHeader></appHeader>
      <appMenu v-if="$route.name !== 'LoginView' && $route.name !== 'ManualAnnotationView'"></appMenu>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <appFooter v-if="$route.name !== 'LoginView' && $route.name !== 'ManualAnnotationView'"></appFooter>
</template>
<script>

import Header from "../src/components/Header.vue"
import Footer from "../src/components/Footer.vue"
import Menu from "../src/components/Menu.vue"

export default {
  name: 'App',
  components: {
    appHeader: Header,
    appFooter: Footer,
    appMenu: Menu,
  }
}
</script>

<style lang="scss" src="./assets/css/App.scss"/>
