<template>
    <input type="file" ref="file" style="display: none" @change="changeFile()" :accept="allowedTypeFiles">
    <button class="pad" @click="selectFile()"> {{ $t("upload.fileChose") }} </button>
</template>

<script>

import { useCookies } from 'vue3-cookies'

export default {
    name: "Button",
    data () {
        return {
            importedFile: {},
            allowedTypeFiles: '.pdf,.doc,.docx,.txt,.rtf,.jpeg,.jpg,.png',  // use lowercase
            // allowedTypeFiles: '.doc,.docx,.pdf,.rtf,.html,.xls,.xlsx,.csv,.txt,.odt,.odm'
        }
    },
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    methods:{
        selectFile () {
            if (this.$store.state.loggedUser) {
                let fileInputElement = this.$refs.file
                fileInputElement.click()
            } else {
                this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.notLoggedIn")})
            }
        },
        changeFile () {
            this.importedFile = this.$refs.file.files[0]
            // console.log(this.$refs.file.files[0])
            if (this.checkFileType(this.importedFile.name)) {
                this.$root.importedFileName = this.importedFile.name
                this.$store.commit('setDocuments', this.importedFile)
                // console.log(this.$refs.file.files[0])
                this.$router.push({
                    name: 'FormView',
                    params: {file: this.$refs.file.files[0]}
                })
            } else {
                this.showModal = true
            }
        },
        checkFileType (testingFile) {
            // create an array with suffixes, then use find instead, if find returns "undefined" then return false, otherwise true.
            let result = false
            let fileExt = this.getExtension(testingFile)
            this.allowedTypeFiles.split(',').forEach((element)=>{
                if (element.replace('.', '') == fileExt){
                    result = true
                }
            })
            return result
        },
        getExtension (filename) {
            var parts = filename.split('.')
            return String(parts[parts.length - 1]).toLowerCase()
        },
    }
}
</script>

<style lang="scss" src="@/assets/css/Button.scss"></style>