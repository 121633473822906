<template>
<div class="footer">
  <div class="footerFlex">
    <div class="logoSection">
      <img class="imgLogoFooter" src="../assets/minexLogo1.png" alt="">
    </div>
    <div class="footerContent">
      <div>
        {{ $t("footer.createdBy") }}
      </div>      
    </div>
     <div class="right">
      <div>
      </div>      
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped src="@/assets/css/Footer.scss"></style>
