import { createApp, h } from 'vue'
import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from 'axios'
import VueAxios from 'vue-axios'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';

const { cookies } = useCookies();
//localization
import { createI18n } from 'vue-i18n';

library.add(fas, faSquare)
dom.watch();

//all localization JSONs
import messages from './lang';

//css used for reseting
//import "reset-css";

const i18n = createI18n({
  locale: "sk",
  fallbackLocale: "en",
  messages,
})

// no cache in response from axios
if (EnvProvider.value('ENV') === 'DEV'){
  // FOR DEVELOPMENT ONLY
  axios.defaults.withCredentials = true
}

// Reroute to home if server returns 401 unauthorized
// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   console.log(error)
//   if (error.response.status === 401) {
//     router.push('/');
//     store.commit('pushMsg', {type: 'error', text: i18n.global.t("authentication.unauthorized")});
//   }
//   return Promise.reject(error)
// });

// Add CSRF token in header if logged in
axios.interceptors.request.use( function (config) {
      let csrf = cookies.get('csrfToken')
      if(store.state.loggedUser === 'testuser' && EnvProvider.value('ENV') === 'DEV'){   
        config.headers['x-csrf-token'] = 'dev_bypass';
        csrf = 'dev_bypass'
      }
      if(!csrf){
        store.commit('setLoggedUser', '');
      }
    
      if(store.state.loggedUser != ''){   
        config.headers['x-csrf-token'] = csrf;
      }
      return config;
    },
    error => {
      console.log(error)
      return Promise.reject(error);
    }
);

const app = createApp({
  el: '#app',
  store: store,
  data() {
    return {
      importedFileName: 'upload.uploadFile',
      anonymizedFields: {},
      helpModalShow: false,
      API_POST: '/post/anonymize',
      API_CRZ: '/getCRZLink',
      API_ANON: '/post/anonymizeAfterManualAnotation',
      API_GET: '/get/documents',
      API_ZIP: '/get/allfiles',
      API_DELETE: '/deletedocument',
      API_LOGIN: '/login',
      API_LOGOUT: '/logout',
      API_AUTH: '/auth',
      TOKEN_CSRF: 'dev_bypass',
      API_URL: 'https://tan.anonymizuj.sk',
      // API_URL: 'http://localhost:5003',
      suggesterLabelObject: {
        'birth_id': i18n.global.t("resultview.BirthNumber"), 
        'date': i18n.global.t("downloadview.date"), 
        'email_address': i18n.global.t("contactInformation.email"), 
        'iban': i18n.global.t("bankInformation.iban"), 
        'postal_code': i18n.global.t("apartmentInformation.postalcode"), 
        'phone_number': i18n.global.t("contactInformation.telephonenumber"), 
        'streets': i18n.global.t("apartmentInformation.street"), 
        'cities': i18n.global.t("apartmentInformation.town"), 
        'price': i18n.global.t('suggesterLabelObject.sum'), 
        'names': i18n.global.t('suggesterLabelObject.name'), 
        'ico': i18n.global.t('suggesterLabelObject.ico'), 
        'dic': i18n.global.t('suggesterLabelObject.dic'), 
        'icdph': i18n.global.t('suggesterLabelObject.icdph'), 
        'variabilny_symbol': i18n.global.t('suggesterLabelObject.variableSymbol'), 
        'faktura': i18n.global.t('suggesterLabelObject.invoiceNumber')
      }
    }
  },
  methods: {
    setAnonymizedFields () {
      return {
        basicInformation: {
          label: "resultview.BasicInfo",
          show: true,
          parameters: {
            name: { id: 'name', label: "resultview.FirstName", type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.name' },
            surname: { id: 'surname', label: "resultview.Surname", type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.surname' },
            birthdate: { id: 'birthdate', label: 'resultview.dateOfBirth', type: 'date', datatype: 'date', input: '', helpPhrase: 'helpPhrase.dateOfBirth' },
            birthtown: { id: 'birthtown', label: 'resultview.BirthPlace', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.birthPlace' },
            birthnumber: { checkLength: 'birthNumber', id: 'birthnumber', label: 'resultview.BirthNumber', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.birthNumber' },
            familystatus: { id: 'familystatus', label: 'resultview.familyStatus', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.familyStatus' },
            gender: { id: 'gender', label: 'resultview.gender', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.gender' },
            title: { id: 'title', label: 'resultview.title', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.title' }
          }
        },
        identificationInformation: {
          label: 'personalInfo.personalInfo',
          show: false,
          parameters: {
            ID: { id: 'IDnumber', label: 'personalInfo.idnumber', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.IDNumber' },
            passport: { id: 'passport', label: 'personalInfo.passportNumber', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.passport' }
          }
        },
        bankInformation: {
          label: 'bankInformation.bankInfo',
          show: false,
          parameters: {
            IBAN: { id: 'IBAN', label: 'bankInformation.iban', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.IBAN' },
            cardnumber: { id: 'cardnumber', label: 'bankInformation.cardnumber', type: 'number', datatype: 'string', input: '', helpPhrase: 'helpPhrase.cardnumber' }
          }
        },
        contactInformation: {
          label: 'contactInformation.contactInfo',
          show: false,
          parameters: {
            telephonenumber: { id: 'telephonenumber', label: 'contactInformation.telephonenumber', type: 'string', datatype: 'string', input: '', helpPhrase: 'helpPhrase.telephonenumber' },
            email: { id: 'email', label: 'contactInformation.email', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.email' }
          }
        },
        apartmentInformation: {
          label: 'apartmentInformation.residence',
          show: false,
          parameters: {
            town: { id: 'town', label: 'apartmentInformation.town', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.town' },
            street: { id: 'street', label: 'apartmentInformation.street', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.street' },
            postalcode: { id: 'postalcode', label: 'apartmentInformation.postalcode', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.postalcode' }
          }
        },
        employmentInformation: {
          label: 'employmentInformation.employment',
          show: false,
          parameters: {
            personalnumber: { id: 'personalnumber', label: 'employmentInformation.personalNumber', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.personalnumber' },
            employeesposition: { id: 'employeesposition', label: 'employmentInformation.employeesposition', type: 'text', datatype: 'string', input: '', helpPhrase: 'helpPhrase.employeesposition' },
            employeestelephonenumber: { id: 'employeestelephonenumber', label: 'employmentInformation.phoneNumber', type: 'string', datatype: 'string', input: '', helpPhrase: 'helpPhrase.employeestelephonenumber' }
          }
        }
      }
    },
    clearValues () {
      this.$root.importedFileName = "upload.uploadFile"
      this.anonymizedFields = this.setAnonymizedFields()
    },
  },
  created () {
    this.anonymizedFields = this.setAnonymizedFields()
  },
  render: () => (h(App))
});

app.use(router).use(VueAxios, axios).use(store).use(i18n).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
