<template>
    <div class="flexContainer">
    <div class="flexUpload">

      <div class="flexUploadTitle">
          <img src="../assets/iconUpload.svg"  height="64" alt=""/>
          <h1> {{ $t("upload.anonymizationUpload") }} </h1>
      </div>
    
      <FileUpload />
    </div>
    </div>
</template>


<script>
import FileUpload from "../components/Upload/FileUpload.vue"

export default {
  components: {
    FileUpload,
  }
}
</script>

<style lang="scss" src="@/assets/css/views/HomeView.scss"/>
