<template>
    <div class="login">
        <img src="../assets/minexLogo1.png"  class="login-img" />
        <div class="form-group">
            <label class="form-label" for="username">{{ $t("login.username") }}</label>
            <input name="username" type="text" class="form-input" id="username" v-model="userName" v-on:keyup.enter="authUser">
        </div>
        <div class="form-group">
            <label class="form-label" for="password">{{ $t("login.password") }}</label>
            <input name="password" type="password" class="form-input" id="password" v-model="password" v-on:keyup.enter="authUser">  
        </div>
        <button @click="authUser" class="login-submit">{{ $t("login.confirm") }}</button>
    </div>
</template>

<script>
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "Login",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data() {
        return {
            userName: '',
            password: '',
            message: ''
        }
    },
    methods: {
        authUser () {
            // read cookie, if no cookie, then login, otherwise cookie!!
            if(this.userName == '' || this.password == '') {
                return null
            }

            let formData = new FormData()
            formData.append('username', this.userName)
            formData.append('password', this.password)
            this.axios.post(EnvProvider.value('API_URL') + this.$root.API_LOGIN, formData).then(result => {
                //console.log(result.status)
                if (result.data['logged_in']) {
                    this.message = null;
                    var csrf = this.cookies.get('csrfToken')
                    console.log(csrf)
                    if(this.userName === 'testuser@sensedata.sk' && EnvProvider.value('ENV') === 'DEV'){   
                        csrf = this.$root.TOKEN_CSRF
                    }
                    // Check if cookies are set (only checks CSRF cookie as the other one is httpOnly)
                    if (!csrf) {
                        console.log('Token is not set.')
                        this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.badUserNameOrPassword")})
                    } else {
                        // Set logged in user
                        this.cookies.set('userName', this.userName.split('@')[0], "30min", null, EnvProvider.value('HOST_DOMAIN'))
                        this.$store.commit('setLoggedUser', this.userName.split('@')[0])
                        this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.welcomeToMinex")})
                        this.$router.push("/selectProduct")
                    }                           
                } 
                else {
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.badUserNameOrPassword")})
                }
            }).catch((error) => {
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.badUserNameOrPassword")})
                    return Promise.reject(error)
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/views/LoginView.scss"></style>