<template>
  <div class="modalHeader">
    <h1 class="pageTitle"> {{ $t("help.title") }} </h1>
    <button class="closeHelpModalWindow" @click="$root.helpModalShow = !$root.helpModalShow"><img src="../assets/NOT_icon.svg" class="notIcon"></button>
  </div>
  <div class="helpSection">
      <p class="helpSectionText"> {{ $t("help.description") }} </p>
      <!-- <div class="border_bottom"></div> -->
      <div v-for="(exp, expressionIndex) in helpExpressionLength" :key="expressionIndex" class="helpContainer">
        <div class="helpTitle">
          <!-- <h2>{{ $t("help.expressions[" + expressionIndex + "].subtitle") }}</h2> -->
          <img :src="require(`../assets/${imgHelp[expressionIndex]}.svg`)" class="imgHelpModal" alt="">
          <!-- <img src="../assets/button_home.svg" alt=""> -->
          </div>
        <div class="helpContent">
          <h3> {{ $t("help.expressions[" + expressionIndex + "].label") }} </h3>
          <p> {{ $t("help.expressions[" + expressionIndex + "].description") }} </p>
          <!-- <p class="bold-font"> {{ $t("help.example") }} </p> -->
          <!-- <div class="exampleUseContainer">
          <div v-for="(exam, exampleIndex) in helpExpressionExampleLength[expressionIndex]" :key="exampleIndex" class="exampleIndex">
            <p class="examples"> <u> {{ $t("help.expressions[" + expressionIndex + "].example[" + exampleIndex + "]") }} </u> </p>
          </div>
          </div> -->
        </div>

        
      </div>
    </div>
</template>

<script>

export default {
    name: "HelpModal",
    data () {
        return {
          helpExpressionLength: 5,
          helpExpressionExampleLength: [3, 1, 3, 2, 2, 2, 3 ],
          imgHelp: ["button_anotovanie", 'button_obnovenie', 'button_volna_an','button_download','button_export']
        }
    },
    methods: {
      getPhoto(index) {
        return this.imgHelp[index];
      }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/HelpModal.scss"></style>

