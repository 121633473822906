<template>
<div class="uploadDocument">
  <!-- <p> {{ $t($root.importedFileName) }} </p> -->
  <div class="uploadSection">
    <Button/>
    <p> {{ fileName }} </p>
  </div>
</div>
</template>

<script>

import Button from "./UploadButton.vue"
import store from "../../store"

export default {
  data () {
    return {
      fileName: (store.state.arrayOfDocuments != null ) ? store.state.arrayOfDocuments.name : this.$t("upload.noFileChosen")
    }
  },
  components:{
    Button
  },
}
</script>

<style lang="scss" src="@/assets/css/FileUpload.scss"></style>