import { createStore } from "vuex";

const store = createStore({
  state: {
    arrayOfDocuments: null,
    msg: [],
    userToken: null,
    loggedUser: '',
    showOverlay: true,
  },
  mutations: {
    setDocuments (state, arrayOfDocuments) {
      state.arrayOfDocuments = arrayOfDocuments
    },
    pushMsg (state, data){
      state.msg.push({
        type: data.type, 
        text: data.text
      })
      setTimeout(() => {
        state.msg.shift()
      }, 7000)
    },
    popMsg (state) {
      state.msg.shift()
    },
    // setUserToken(state, usertoken){
    //   state.userToken = usertoken
    // },
    setLoggedUser(state, loggedUser) {
      state.loggedUser = loggedUser
    },
    setshowOverlay(state, showOverlay) {
      state.showOverlay = showOverlay  
    }
    // deleteUserToken(state){
    //   state.userToken = null
    //   state.userName = null
    // }
  }
})

export default store
