<template>
    <pdfviewer         
        :documentUrl="$route.params.fileUrl"
		:fileId="$route.params.fileName"
        :minex="$route.params.minexData"
        />
</template>

<script>
import pdfviewer from '../components/PDF/pdfviewer.vue'

export default {
    components: {
        pdfviewer,
    },
}
</script>