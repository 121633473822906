<template v-if="$store.state.msg.length > 0">
<!-- <div>{{$store.state.msg.length > 0}}</div> -->
    <div class="infoBox">
        <div v-for="(value, index) in $store.state.msg.length" id="info-outer" :class="'info-'+$store.state.msg[index].type">
            <div class="info-outer-container">
                <img src="../assets/ok.svg" alt="" class="ok-img">
                <div id="info-textbox">{{$store.state.msg[index].text}}</div>
                <img src="../assets/x.svg" alt="" class="x-img" @click="$store.commit('popMsg')">
                <!-- <div id="info-close" @click="$store.commit('popMsg')" >x</div> -->
            </div>
            <!-- {{$store.state.msg[0]}} -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Info',
    // props: ["csstype", "message"],
    setup() {
    },
    mounted(){
        // console.log(this.$store.state)
    },
    data() {
        return {
            // type: "info-ok"
        }
    },
    methods: {
        
    },
};
</script>

<style lang="scss" src="@/assets/css/Info.scss"></style>
