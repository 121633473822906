<template>
    <LoadingOverlay :message="message"/>
    <div id="selectorDiv" v-on:mouseup="onmouseup" v-on:mousemove="onmousemove" style="border: 2px solid #000; position: absolute; z-index:5"></div>
    <div class="viewContainer"> 
        <div class="leftSideBar">
            <div class="sideBarContainer leftContainer">
                <div>
                  <div class="selectContainer">
                        <img src="../../assets/button_home.svg" alt="" @click="prelinkToMinex()">
                        <img src="../../assets/button_obnovenie.svg" alt="" v-on:click="reRenderPdf(true)" :title='$t("pdfviewer.docRefresh")'>
                        <img src="../../assets/button_anotovanie.svg" alt="" v-on:click="addHighlightAnnotation" :title='$t("pdfviewer.textAnotation")'>
                        <img src="../../assets/button_volna_an.svg" alt=""  v-on:click="addSquareAnnotation" :title='$t("pdfviewer.freeAnotation")' :class="{ activeButton: doSquare }">                        
                    </div>

                    <div class="itemContainer">
                        <h3>Anotacia v texte</h3>
                    </div>   

                    <div id="annotationContainer">
                            <!-- <b>{{ $t("pdfviewer.textanotation") }}</b> -->
                            <div id="annotations" v-for="(annotation, index) in annotations" :key="index">
                                <div v-for="(annot, index2) in annotation" :key="index2">
                                    <div v-if="checkAnnotType(annot.type) && this.controlDeletedObjects(annot.id, annot.page)" class="annotation">
                                        <div v-on:click="markAnnotation(annot)">
                                            <div class="textAnnotationSection">
                                                {{ annotationTypeFormatter(annot.type) }}
                                                <label class="annotationPage"> {{ annotationPageFormatter(annot.page) }} </label>
                                            </div>
                                        </div>
                                        <div class="deleteAnnotationSection">
                                            <img v-on:click="deleteAnnotation(annot.id, annot.page)" class="deleteAnnotationBtn" src="../../assets/NOT_icon.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div>
                    <div class="itemContainerButton">
                        <button class="downloadDocButton" v-on:click="download" id="download" :title='$t("pdfviewer.downloadDoc")'>{{ $t("pdfviewer.downloadDoc") }}</button>
                    </div>
                    <div class="itemContainerButton">
                        <button class="sendDocButton" v-on:click="pushPdf" id="pushPdf" :title='$t("pdfviewer.sendForAnonymization")'>{{ $t("pdfviewer.sendForAnonymization") }}</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div id="view">
            <div id="viewerContainer" :style="{height: heightOfViewer + 'px' }" v-on:mousedown="onmousedown" v-on:mouseup="onmouseup" v-on:mousemove="onmousemove" style="padding-top: 30px;padding-bottom: 30px;">
                <div id="viewer" class="pdfViewer"></div>
            </div>
        </div>
        
        <div class="rightSideBar">
            <div class="sideBarContainer">
                <div class="itemContainer">
                    <h3>{{ $t("pdfviewer.textrecomendation") }}</h3>
                </div>   

                <div id="suggestedAnnots">
                    <div class="selectAll">
                        <span class="annotation">
                            <input class="inputCheckAll" type="checkbox" v-model="checkAll" id="idCheckAll" @click="suggestedCheckboxState">
                            <label class="labelCheckAll"> {{ checkAll == true ? $t("pdfviewer.cancelAnnotation") : $t("pdfviewer.selectAll") }} </label>
                        </span>
                    </div>
                    <span class="annotation" v-for="(item, key) in suggestedAnnotations" :key="key">
                        <input class="suggestedInputField" :checked="isCheckedAnnot(key)" type="checkbox" @change="highLightSuggested(item.coordinates, item.pageNumber, key)"
                            :name="item.category + ' - ' + item.phrase" :value="item.phrase" :id="key"/>
                        <div class="textAnnotationSection textRecommendSection">
                            <label> {{ setLabelForRecommendCategory(item.category) }} </label>
                            <label> <b> {{ item.phrase }} </b> </label>
                            <label class="annotationPage"> {{ "Strana: " + String(item.pageNumber + 1) }} </label>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import '../../../node_modules/pdfjs-dist/web/pdf_viewer.css'
import '../../../node_modules/pdfjs-dist/build/pdf.js'
import { AnnotationFactory } from '../../../public/pdfAnnotate.js'
import { getDocument , GlobalWorkerOptions } from 'pdfjs-dist'
import { PDFViewer } from '../../../node_modules/pdfjs-dist/web/pdf_viewer.js'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';
import LoadingOverlay from "../LoadingOverlay.vue"

/** TODO: disable send/save button until promise from storing the new annotation is returned as successful 
 * 
 */
export default {


    name: "pdfviewer",
    props: ["documentUrl", "fileId", "minex"],
    setup () {
        const { cookies } = useCookies();
        return { cookies };
    },
    data: function () {
        return {
            color: "#BFC547",
            pdfurl: '',
            annotations: undefined,
            pdfFactory: undefined,
            pdfViewer: undefined,
            loadTask: undefined,
            pdfData: undefined,
            pdfPages: 0,
            /*on the fly coordiinates*/
            once: false,
            pageNumberCustomRect: 0,
            doSquare: false,
            coordinates: [],
            htmlCoords: [],
            htmlcoordinates:  [],
            initCoords: [], 
            /*coordinates to render into html after refresh
            the coordinates should reflect the contents of the annotations of the documents without the flag "delete" set to True
            */
            renderedHtmlCoord: {},
            author: "",
            comment: "",
            annotCount: 0,
            pagesRendered: 0, //the number of rendered text for pages in the current render cycle
            // devicePixelRatio: window.devicePixelRatio 
            showOverlay: false,
            suggestedAnnotations: {},
            checkedAnnots: {},
            checkAll: false,
            toggleAnnotMenu: true,
            toggleSuggestMenu: true,
            heightOfViewer: '25px',
            selectorDiv: undefined,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
            message: this.$t("LoadingMessages.pdfLoading")
        }
    },
    mounted () {
        // console.log('mounted')
        this.load()
        this.renderPdf()
            
    },
    methods: {
        prelinkToMinex() {
            window.location.href = EnvProvider.value('HOME_URL') + '/selectProduct'
        },
        // for selecting rectangle
        // http://jsfiddle.net/jLqHv/

        reCalc() { //This will restyle the div
            let x3 = Math.min(this.x1,this.x2); //Smaller X
            let x4 = Math.max(this.x1,this.x2); //Larger X
            let y3 = Math.min(this.y1,this.y2); //Smaller Y
            let y4 = Math.max(this.y1,this.y2); //Larger Y
            this.selectorDiv.style.left = x3 + 'px';
            this.selectorDiv.style.top = y3 + 'px';
            this.selectorDiv.style.width = (x4 - x3) + 'px';
            this.selectorDiv.style.height = (y4 - y3)  + 'px';
            // console.log(this.selectorDiv)
        },
        onmousedown(e) {
            if (this.doSquare) {
                this.selectorDiv.style.visibility = "visible"; //Unhide the div
                let html = document.getElementsByTagName('html')[0]
                let top = html.scrollTop
                let left = html.scrollLeft
                let x = (e.clientX + left)
                let y = (e.clientY + top)
                // y = y % (this.pdfViewer._pages[0].canvas.height + 10)
                this.x1 = x
                this.y1 = y
                this.x2 = x
                this.y2 = y
                this.reCalc();
            }
            this.processCoordinates(e)
            // save one point
        },
        onmousemove(e) {
            if (this.doSquare && this.htmlcoordinates.length == 2){
                let html = document.getElementsByTagName('html')[0]
                let top = html.scrollTop
                let left = html.scrollLeft
                let x = (e.clientX + left)
                let y = (e.clientY + top)
                // y = y % (this.pdfViewer._pages[0].canvas.height + 10)
                this.x2 = x;
                this.y2 = y;
                this.reCalc();
            }
        },
        onmouseup(e) {
            this.calculatePoints(e)
            if (this.doSquare && this.htmlcoordinates.length == 2){
                console.log("mouseup") 
                this.selectorDiv.style.visibility = "hidden"; //Hide the div
                // save second point
                this.cleanSelectArea()
                this.processCoordinates(e)
            }
        }, 

        cleanSelectArea(){
                this.selectorDiv.style.left = '0px';
                this.selectorDiv.style.top = '0px';
                this.selectorDiv.style.width = '0px';
                this.selectorDiv.style.height = '0px';
                this.x1 = 0
                this.x2 = 0
                this.y1 = 0
                this.y2 = 0
        },

        changeRouting (viewName) {
            console.log(viewName)
            this.$root.clearValues()
            this.$router.push({
            name: viewName,
            })
        },
        // debug(event) {
        //     console.log(this.$data)
        // },
        isCheckedAnnot(key) {
            return (typeof this.checkedAnnots[key] == 'object')
        },

        suggestedCheckboxState() {
            if (this.checkAll){
                this.suggestedAnnotations.forEach((value, index, array) => {
                    delete this.checkedAnnots[index]    
                })
                this.reRenderPdf(false)
            }
            else{
                this.selectAllSuggestedAnnot()
            }
        },

        parseHexToRGB(){
            let s = this.color.slice(1,7)
            let aRgbHex = s.match(/.{1,2}/g)
            let aRgb = [
                parseInt(aRgbHex[0], 16),
                parseInt(aRgbHex[1], 16),
                parseInt(aRgbHex[2], 16)
            ]
            return aRgb;
        },

        // Pato ==================



        // =======================

        getParameters() {

                let x = this.coordinates[0]
                let y = this.coordinates[1]

                return [x, y, this.comment, this.author]
        },

        load() {
            // init values, start loading pdf
            this.pdfurl = this.documentUrl
            this.suggestedAnnotations = JSON.parse(this.minex)
            // this.pdfurl = './annpop.pdf'
            GlobalWorkerOptions.workerSrc = 'pdf.worker.js'
            this.pdfContainer = document.getElementById('viewerContainer')
            this.selectorDiv = document.getElementById('selectorDiv')

            // document.addEventListener('textlayerrendered', () => {
            //     console.log("textlayerrendered fired");
            //     console.log(this)
            // })

            this.pdfViewer = new PDFViewer({
                    container : this.pdfContainer
            })

            this.pdfViewer.eventBus.on('textlayerrendered', () => {
                console.log("Rendered text layer on top of page")                
                this.pagesRendered ++
                // console.log("aaaaa" + this.pagesRendered)
                // console.log(this.pdfViewer.pdfDocument._pdfInfo.numPages)
                if (this.pdfViewer.pdfDocument._pdfInfo.numPages != undefined){
                    if (this.pagesRendered == this.pdfViewer.pdfDocument._pdfInfo.numPages) {
                        this.$store.state.showOverlay = false
                    }
                }
            })
        },

        calculateHtmlCoord(coordinates){
            let htmlCoords = []
            return htmlCoords
        },

        setAnnotCount(n){
            this.annotCount = n
            // console.log(n)
        },
        setXCSRFToken() {
            if(this.$store.state.loggedUser === 'testuser' && EnvProvider.value('ENV') === 'DEV'){
                return this.$root.TOKEN_CSRF
            } else {
                return this.cookies.get('csrfToken')
            }
        },
        async renderPdf(event = undefined){
            // render the PDF
            this.$store.state.showOverlay = false
            this.loadTask = getDocument({
                url: this.pdfurl,
                httpHeaders: { "x-csrf-token": this.setXCSRFToken() },
            })
            console.log("rendering...");
            this.loadTask.promise.then((pdfDocument) => {
                    pdfDocument.getData().then((data) => {
                            this.pdfFactory = new AnnotationFactory(data)
                            this.pdfFactory.getAnnotations().then(result => {
                                this.setAnnotCount(result[0].length)
                                // console.log(result[0].length)
                                // this.setAnnotations()
                            })
                    })
                    // console.log(EventBus)
                    this.pdfViewer.setDocument(pdfDocument)
                    // console.log(this.pdfViewer)
                    // console.log(this.pdfViewer.pdfDocument)
                    this.pdfPages = this.pdfViewer.pdfDocument._pdfInfo.numPages
                    this.heightOfViewer = this.pdfPages * 1056
                    console.log("rendering pages: ", this.pdfPages);
                    let iter = 0
                    let upperLimit =  this.pdfPages*20 // the upper limit of time loading can occur

                    let interval = setInterval(()=> {
                        if (this.pdfPages == this.pagesRendered) {  
                            console.log("settings annotations in "+iter+"*150ms")
                            window.clearInterval(interval)
                            this.setAnnotations()
                            var pages = document.getElementsByClassName("page");
                            for (let i = 0; i < pages.length; i++) 
                            {
                            pages[i].style.border = "none";
                            pages[i].style.marginBottom = "10px";
                            }
                            console.log("len: ", pages.length);
                        }
                        console.log("pagesRendered: ", this.pagesRendered);

                        if (++iter === upperLimit) {
                            this.$store.state.showOverlay = true  
                            window.clearInterval(interval)
                            let time = this.pdfPages*20*450
                            this.$store.commit('pushMsg', {type: 'error', text: 'Dokument sa nepodarilo načítať. Doba načítania' + +time})
                            console.error("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: "+time)
                            alert("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: "+time)
                        }
                    }, 450)

            })
        
        },

        reRenderPdf(reloadAnnotations){
            this.$store.state.showOverlay = true  
            // reseting this.pagesRendered
            this.pagesRendered = 0 
            this.pdfViewer = new PDFViewer({
                    container : this.pdfContainer
            })
            
            this.loadTask.promise.then((pdfDocument) => {
                    pdfDocument.getData().then((data) => {
                        if (reloadAnnotations) {
                            this.pdfFactory = new AnnotationFactory(data)
                        }
                    })
                    this.pdfViewer.setDocument(pdfDocument)
                    
                    let iter = 0
                    let upperLimit =  this.pdfPages*20

                    let interval = setInterval(()=>{
                            if (this.pdfPages == this.pagesRendered) {
                                // console.log("Rerender, settings annotations in "+iter+"*150ms")
                                window.clearInterval(interval);
                                this.setAnnotations()
                                 var pages = document.getElementsByClassName("page");
                                 for (let i = 0; i < pages.length; i++) 
                                 {
                                    pages[i].style.border = "none";
                                    pages[i].style.marginBottom = "10px";
                                }

                                for (let key in this.checkedAnnots) {
                                    //redrawing one
                                    // console.log(this.checkedAnnots[key])
                                    // console.log('page'+String(this.checkedAnnots[key].page+1))
                                    if (typeof this.checkedAnnots[key].newRect == 'object'){ //NEWRECT
                                        this.drawAnnotation(
                                            this.checkedAnnots[key].newRect[0],
                                            this.checkedAnnots[key].newRect[2],
                                            this.checkedAnnots[key].newRect[1],
                                            this.checkedAnnots[key].newRect[3],
                                            'page'+String(this.checkedAnnots[key].page+1)
                                        )
                                    } 
                                    if (typeof this.checkedAnnots[key].newRects == 'object'){ //NEWRECTS << working with multiple rectangles
                                        for (let one in this.checkedAnnots[key].newRects){
                                            this.drawAnnotation(
                                                this.checkedAnnots[key].newRects[one][0],
                                                this.checkedAnnots[key].newRects[one][2],
                                                this.checkedAnnots[key].newRects[one][1],
                                                this.checkedAnnots[key].newRects[one][3],
                                                'page'+String(this.checkedAnnots[key].page+1)
                                            )
                                        }
                                    }
                                }
                                
                            }
                        if (++iter === upperLimit) {
                            window.clearInterval(interval);
                            let time = this.pdfPages*20*450
                            this.$store.commit('pushMsg', {type: 'error', text: 'Dokument sa nepodarilo načítať. Doba načítania' + +time})
                            console.error("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: "+time)
                            alert("The number of cycles exceeded limit. Please try again or contact support. Allocated miliseconds: "+time)
                        }
                    }, 450)
            })
            
        // render annotation into canvas
        },
        // async setPDF(pdfDocument) {
        //     await this.setDocument(pdfDocument)
        //     console.log('som tu')
        //     this.setAnnotations()
        // },
        // setDocument(pdfDocument) {
        //      return new Promise((resolve,reject) => {
        //          this.pdfViewer.setDocument(pdfDocument)
        //      })
        // }, 
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
        
        highLightSuggested(coords, page, id) {
            /* TODO: consider also hig hightlights going through pages?
            */
            let newRect = []
            let newRects = []
            if (typeof(coords.x0) === 'undefined'){
                coords.forEach(element => {
                    let pageNumber = 'page'+String(page+1) 
                    let rect = this.calculateCoordinatesForAnnot ([element.x0,
                        element.y0,
                        element.x1,
                        element.y1], page)
                    newRects.push(rect)
                    // console.log("NEWRECT:")
                    // console.log(rect)
                    this.drawAnnotation(
                        rect[0],
                        rect[2],
                        rect[1],
                        rect[3],
                        pageNumber
                    )
                });
            
            } else {

                let pageNumber = 'page'+String(page+1) 
                newRect = this.calculateCoordinatesForAnnot(
                    [coords.x0,
                    coords.y0,
                    coords.x1,
                    coords.y1], page)
                // console.log("NEWRECT:")
                // console.log(newRect)
                this.drawAnnotation(
                    newRect[0],
                    newRect[2],
                    newRect[1],
                    newRect[3],
                    pageNumber
                )
            }
            // console.log(newRect)
            let idx = this.checkedAnnots[id]
            if ( idx == undefined ){
                if (newRect.length != 0) {
                    this.checkedAnnots[id] = {newRect, page}
                }
                else if (newRects.length != 0) {
                    this.checkedAnnots[id] = {newRects, page}
                }
            } else {
                // remove from array.
                delete this.checkedAnnots[id]
                this.reRenderPdf(false)
                // redraw checked annots ^
            }

            // console.log(Object.keys(this.checkedAnnots).length > 0)

            if(Object.keys(this.checkedAnnots).length > 0) {
                this.checkAll = true
            } else if(Object.keys(this.checkedAnnots).length == 0) {
                this.checkAll = false
            }
            // Adding annotations TO pdf shloud be done when saving file
            // console.log(this.checkedAnnots)
        },

        selectAllSuggestedAnnot() {
            this.suggestedAnnotations.forEach((value, index, array) => {
                this.highLightSuggested(value.coordinates, value.pageNumber, index)
            })

        } ,

        // copypaste code. :/ TODO: FIX
        calculateCoordinatesForAnnot (rect, actualPage) {
            const scale = this.pdfViewer._pages[actualPage].viewport.scale
            const scale2 = window.devicePixelRatio
            let rec = rect
            let x_1 = (rec[0] * (scale2 * scale))
            let y_1 = (rec[1] * (scale2 * scale))
            let x_2 = (rec[2] * (scale2 * scale))
            let y_2 = (rec[3] * (scale2 * scale))
            return [x_1, y_1, x_2, y_2]
        },

        annotationTypeFormatter (annotType) {
            if(annotType == "/Highlight")
                return "Textová anotácia"
            else if (annotType == "/FreeText" || annotType == "/Square" )
                return "Voľná anotácia"
            else return annotType
        },
        annotationPageFormatter (annotPage) {
            return "Strana" + (annotPage + 1)
        },
        updateCoordinates() {
                let _str = this.coordinates.map((x) => Math.round(x)).join(",")
        },

        selectionCoordinates(x_1, y_1, x_2, y_2, actualPage) {
                console.log(actualPage)
                let x_1_y_1 = this.pdfViewer._pages[actualPage].viewport.convertToPdfPoint(x_1, y_1)
                x_1 = x_1_y_1[0]
                y_1 = x_1_y_1[1]
                let x_2_y_2 = this.pdfViewer._pages[actualPage].viewport.convertToPdfPoint(x_2, y_2)
                x_2 = x_2_y_2[0]
                y_2 = x_2_y_2[1]
                // console.log('rect before' + x_1, y_1, x_2, y_2)
                return [x_1, y_1, x_2, y_2]
        },
        setAnnotations () {
            var annotations = this.pdfFactory.getAnnotations()
            annotations.then(value => {
                this.annotations = value
                for (var i in value) {
                    for (var annotation in value[i]) {
                        // console.log(value[i][annotation].id)
                        if(this.checkAnnotType((value[i][annotation].type)) && this.controlDeletedObjects(value[i][annotation].id, value[i][annotation].page)) {
                            let actualPage = value[i][annotation].page
                            const htmlCoords = this.calculateCoordinatesFromCustomRect(value[i][annotation].rect, actualPage)
                            // console.log("FORMER rect:")
                            // console.log(value[i][annotation].rect)
                            // console.log("HTML rect:")
                            // console.log(htmlCoords)
                            // console.log(htmlCoords.map(function(x) { return x * window.devicePixelRatio }))
                            this.drawAnnotation(htmlCoords[0],htmlCoords[2],htmlCoords[1],htmlCoords[3],"page" + (actualPage + 1))
                        }
                    }
                }
            })
        },
        controlDeletedObjects (id, page) {
            if (this.pdfFactory.toDelete) {
                for (var deleted of this.pdfFactory.toDelete) {
                    if(deleted.id === id && deleted.page == page) {
                        return false
                    }
                }
            } 
            return true
        },
        refreshListOfAnnotations () {
            var annotations = this.pdfFactory.getAnnotations()
            annotations.then((value) => {
                this.annotations = value
            })
        },
        setLabelForRecommendCategory (categoryId) {
            return this.$root.suggesterLabelObject[categoryId]
        },
        checkAnnotType (annotType) {
            if(annotType == "/FreeText" || annotType == "/Highlight" || annotType == "/Square") {
                return true
            }
            else return false
        },
        markAnnotation(annotation) {
            this.reRenderPdf(false)
            let iter = 0
            let interval = setInterval(()=>{
                if (this.pdfPages == this.pagesRendered) {
                    window.clearInterval(interval);
                    let actualPage = annotation.page
                    const htmlCoords = this.calculateCoordinatesFromCustomRect(annotation.rect, actualPage)
                    this.drawStrokeAnnotation(htmlCoords[0],htmlCoords[2],htmlCoords[1],htmlCoords[3],"page" + (actualPage + 1))
                }
                if (++iter === this.pdfPages*4) {
                    window.clearInterval(interval);
                    this.$store.commit('pushMsg', {type: 'error', text: 'Danú anotáciu sa nepodarilo načítať. Doba načítania' + +this.pdfPages*4*450})
                    console.error("The number of cycles exceeded limit. Please try again or contact support.")
                }
            }, 150)
        },
        deleteAnnotation (annotID, annotPage) {
            this.pdfFactory.deleteAnnotation(annotID, annotPage)
            this.reRenderPdf(false)
        },
        calculateCoordinatesForHtml () {
                // const scale2 = window.devicePixelRatio
                let rec = window.getSelection().getRangeAt(0).getBoundingClientRect()
                let ost = this.computePageOffset()
                let x_1 = (rec.x - ost.left)
                let y_1 = (rec.y - ost.top)
                let x_2 = (x_1 + rec.width)
                let y_2 = (y_1 + rec.height)
                return [x_1, y_1, x_2, y_2]
        },
        calculateCoordinatesFromCustomRect (rect, actualPage) {
            const scale = this.pdfViewer._pages[actualPage].viewport.scale
            const scale2 = window.devicePixelRatio
            const maxX = this.pdfViewer._pages[actualPage].viewport.width
            var maxY = this.pdfViewer._pages[actualPage].viewport.height
            maxY =  maxY * scale2
            let rec = rect
            let x_1 = (rec[0] * (scale2 * scale))
            let y_1 = maxY - (rec[1] * (scale2 * scale))
            let x_2 = (rec[2] * (scale2 * scale))
            let y_2 = maxY - (rec[3] * (scale2 * scale))
            return [x_1, y_1, x_2, y_2]
        },
        drawAnnotation(x1,x2,y1,y2,page) {
            // await this.until(document.getElementById(page))
            // console.log(page)
            let ctx = document.getElementById(page).getContext("2d")
            // console.log("Drawing to page " + page +" "+ x1 +" "+ x2 +" "+ y1 +" "+ y2)
            ctx.globalAlpha = 0.4
            ctx.fillStyle = this.color
            ctx.fillRect(
                x1,
                y1,
                (x2-x1),
                (y2-y1)
            )
            ctx.stroke()
        },
        drawStrokeAnnotation(x1,x2,y1,y2,page) {
            let ctx = document.getElementById(page).getContext("2d")
            ctx.strokeStyle = 'red'
            ctx.strokeRect(
                x1,
                y1,
                (x2-x1),
                (y2-y1)
            )
            // console.log('farbim')
        },
        computePageOffset() {
                let pageId = "page" + this.pdfViewer.currentPageNumber
                let pg = document.getElementById(pageId)

                var rect = pg.getBoundingClientRect(), bodyElt = document.body;
                return {
                        top: rect.top + bodyElt.scrollTop,
                        left: rect.left + bodyElt.scrollLeft
                }
        },

        clear(){
            this.coordinates = []
            this.htmlCoords = []
            this.htmlcoordinates = []
            this.updateCoordinates()
        },

        processCoordinates(event) {
            console.log(event)
            // for clicking inside of the pdf. Takes the coordinates and uses them, etc. 
            const scale2 = window.devicePixelRatio

            let ost = this.computePageOffset()
            let x = (event.clientX - ost.left)
            let y = (event.clientY - ost.top)
            this.pageNumberCustomRect = Math.floor(y / (((this.pdfViewer._pages[0].canvas.height + (10 * scale2)) / scale2)))

            y = y % ((this.pdfViewer._pages[0].canvas.height + (10 * scale2)) / scale2)

            if (this.doSquare) {
                this.htmlcoordinates.push(x,y)
            }
            
            let x_y = this.pdfViewer._pages[this.pageNumberCustomRect].viewport.convertToPdfPoint(x, y)
            x = x_y[0]
            y = x_y[1]
            // console.log('point after' + x, y)
            this.coordinates.push(x)
            this.coordinates.push(y)

            this.updateCoordinates()

            if (this.doSquare) {
                    // this.htmlcoordinates.push(x,y)
                    // this.setStatus("Označte druhý bod anotácie")
                    if (this.coordinates.length == 4) {
                            // this.setStatus("Štvorcová anotácia bola pridaná")
                            let parameters = this.getParameters()
                            console.log(this.coordinates.slice())  
                            if (!(this.coordinates.slice()[0] == this.coordinates.slice()[2]) & !(this.coordinates.slice()[1] == this.coordinates.slice()[3])){  
                                this.pdfFactory.createSquareAnnotation(this.pageNumberCustomRect, this.coordinates.slice(), parameters[2], parameters[3])
                                // console.log(this.pdfViewer.currentPageNumber - 1, this.coordinates.slice(), parameters[2], parameters[3])
                                // this.pdfFactory.createHighlightAnnotation(pageNumber)
                                this.drawAnnotation(this.htmlcoordinates.slice()[0],this.htmlcoordinates.slice()[2], this.htmlcoordinates.slice()[1], this.htmlcoordinates.slice()[3],"page"+(this.pageNumberCustomRect + 1))
                                this.refreshListOfAnnotations()
                            } else {
                                alert("Anonymizacne políčko je moc malé, bude ignorované. Na označenie väčšej plochy stlač lavé myšítko a potiahni.")
                            }
                            this.coordinates = []
                            this.htmlcoordinates = []
                            this.doSquare = false
                            document.getElementById("viewer").style.userSelect = "auto"
                            document.getElementById("viewer").style.cursor = ""
                            this.reRenderPdf(false)

                    }
            } 
        },

        addHighlightAnnotation() {
            // this.setStatus("Textová anotácia bola pridaná")
            const scale2 = window.devicePixelRatio
            // this.htmlCoords = this.calculateCoordinatesForHtml()
            let actualPage = Math.floor(this.htmlCoords[1] / ((this.pdfViewer._pages[0].canvas.height + (10 * scale2)) / scale2))
            console.log(this.htmlCoords[1])
            this.htmlCoords[1] = this.htmlCoords[1] % ((this.pdfViewer._pages[0].canvas.height + (10 * scale2)) / scale2)
            this.htmlCoords[3] = this.htmlCoords[3] % ((this.pdfViewer._pages[0].canvas.height + (10 * scale2)) / scale2)
            let parameters = this.getParameters()
            let selectedColor = this.parseHexToRGB()
            // console.log(this.selectionCoordinates(this.htmlCoords[0],this.htmlCoords[1],this.htmlCoords[2],this.htmlCoords[3], actualPage))

            this.pdfFactory.createHighlightAnnotation(
                actualPage, 
                this.selectionCoordinates(this.htmlCoords[0],this.htmlCoords[1],this.htmlCoords[2],this.htmlCoords[3], actualPage), 
                parameters[2], 
                'pdfAnnotate', //required so we know how much of the annot can be cut off
                {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]}
            )
            // console.log('coordinates')
            // console.log(this.htmlCoords[0],this.htmlCoords[1],this.htmlCoords[2],this.htmlCoords[3])
            this.drawAnnotation(this.htmlCoords[0] * scale2,this.htmlCoords[2] * scale2,this.htmlCoords[1] * scale2,this.htmlCoords[3] * scale2,"page" + (actualPage  + 1))
            this.coordinates = []
            this.updateCoordinates()
            this.refreshListOfAnnotations()
            this.doSquare = false
        },
        addSquareAnnotation(event = undefined) {
            if(!this.doSquare) {
                    // this.setStatus("Označte prvý bod anotácie")
                    this.coordinates = []
                    this.doSquare = true
                    document.getElementById("viewer").style.cursor = "crosshair"
                    document.getElementById("viewer").style.userSelect = "none"
            }
        },
        calculatePoints (event) {
            this.htmlCoords = this.calculateCoordinatesForHtml()
        },
        // renderCustomRectangle(event) {
        //     if (this.doSquare && this.htmlcoordinates.length == 2) {
        //         let ost = this.computePageOffset()
        //         const scale2 = window.devicePixelRatio
        //         let x = (event.clientX - ost.left) * scale2
        //         let y = (event.clientY - ost.top) * scale2
        //         y = y % (this.pdfViewer._pages[0].canvas.height + 10)
        //         this.drawStrokeAnnotation(this.htmlcoordinates.slice()[0] * scale2, x, this.htmlcoordinates.slice()[1] * scale2, y,"page"+(this.pageNumberCustomRect + 1))
        //     }
        // },
        pushPdf() {
            // send pdf to backend
            // create annotation for checked checkboxes

            for (let key in this.checkedAnnots) {
                const scale = this.pdfViewer._pages[this.checkedAnnots[key].page].viewport.scale
                const scale2 = window.devicePixelRatio
                var maxY = this.pdfViewer._pages[this.checkedAnnots[key].page].viewport.height
                maxY = maxY / scale
                 
                if ((typeof(this.checkedAnnots[key].newRect) !== 'undefined')) {
                    // console.log("ONE RECT!")
                    this.pdfFactory.createHighlightAnnotation(
                        this.checkedAnnots[key].page, 
                        [(this.checkedAnnots[key].newRect[0] / (scale2 * scale)), 
                        (maxY - (this.checkedAnnots[key].newRect[1] / (scale2 * scale))),
                        (this.checkedAnnots[key].newRect[2] / (scale2 * scale)), 
                        (maxY - (this.checkedAnnots[key].newRect[3] / (scale2 * scale)))],
                        "", 
                        "minexPdfAnnotate", 
                        {r : 128, g : 128, b : 128}
                    ) 
                } 
                if (typeof(this.checkedAnnots[key].newRects) !== 'undefined') {
                    // console.log("MORE RECT!") 

                    for (let rect in this.checkedAnnots[key].newRects) {
                        this.pdfFactory.createHighlightAnnotation(
                            this.checkedAnnots[key].page, 
                            [(this.checkedAnnots[key].newRects[rect][0] / (scale2 * scale)), 
                            (maxY - (this.checkedAnnots[key].newRects[rect][1] / (scale2 * scale))),
                            (this.checkedAnnots[key].newRects[rect][2] / (scale2 * scale)), 
                            (maxY - (this.checkedAnnots[key].newRects[rect][3] / (scale2 * scale)))],
                            "", 
                            "minexPdfAnnotate", 
                            {r : 128, g : 128, b : 128}
                        ) 
                    }
                }
            }
              
            let data = this.pdfFactory.write()            
            let formData = new FormData()
            let dataBlob = new Blob([data], {type : 'application/pdf'})
            formData.append('file', dataBlob)
            formData.append('fileName', this.fileId)
            
            setTimeout(() => {
                this.axios.post(
                    EnvProvider.value('API_URL') + this.$root.API_ANON,
                    formData,
                    { headers: { 
                        "Content-Type": "multipart/form-data",
                        'x-csrf-token': this.cookies.get('csrfToken')
                        }
                    }
                ).then((resp) => {
                    // console.log(resp)
                    if (resp.status === 200) {
                        this.$store.commit('pushMsg', {type: 'ok', text: 'Dokument bol úspešne zanonymizovaný'})
                        this.$router.push({
                            name: 'DownloadView'
                        })
                    } else {
                        this.$store.commit('pushMsg', {type: 'error', text: 'Dokument sa nepodarilo zanonymizovať'})
                    }
                })
            }, 500);

        },
        download(){
            this.pdfFactory.download()
        },
    },
}
</script>

<style>
    .activeButton {
        background-color: rgb(112, 200, 230);
    }
</style>

<style lang="scss" scoped src="@/assets/css/Pdfviewer.scss"/>
