<template>
    <!--Why tf doesnt this work ???-->
    <!-- Update: it does work in HomeView.vue -->
    <div>
        <LoadingOverlay :message="message"/>
    </div>
    <div class="highlight">
        <h1> {{ $t("Opened") }} </h1>
    </div>
    <div v-for="(field, index) in $root.anonymizedFields" :key="index" class="formSection">
        <div @click="field.show = !field.show" class="width100">
            <h2>{{ $t(field.label) }}</h2><img v-if="!field.show" src="../assets/plus.svg"><img v-else src="../assets/minus.svg">
        </div>
        <div v-if="field.show" class="formFields">
            <div v-for="(field, index) in field.parameters" :key="index" class="formField">
                <h3 class="formLabel">{{ $t(String(field.label)) }}</h3>
                <input class="formInput" 
                    autocomplete="off"
                    v-model="field.input" 
                    :id="field.id" 
                    :type="field.type" 
                    :description="field.helpPhrase"
                />
            </div>
        </div>
    </div>
    <div class="formSection">
        <div @click="showRandomData = !showRandomData" class="width100">
            <h2> {{ $t("ResultViewButtons.AnyData") }} </h2><img v-if="!showRandomData" src="../assets/plus.svg"><img v-else src="../assets/minus.svg">
        </div>
        <div v-if="showRandomData" class="formFields">
            <div class="formField">
                <h3 class="formLabel"> {{ $t("ResultViewButtons.AnyData") }} </h3>
                <input class="formInput" 
                    autocomplete="off"
                    v-model="randomData" 
                    id="randomData" 
                    type="string" 
                    :description="$t('ResultViewButtons.AnyDataWrite')"
                />
            </div>
        </div>
    </div>
    <div class="formButtons">
        <div>
            <button @click="sendDataIntoAnnotation(false)" class="confirmButton"> {{ $t("ResultViewButtons.ConfirmButton") }} </button>
        </div>
        <div>
            <button @click="sendDataIntoAnnotation(true)" class="secondary_btn"> {{ $t("ResultViewButtons.manualAnotation") }} </button>
        </div>
    </div>
</template>

<script>

import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';
import LoadingOverlay from "../components/LoadingOverlay.vue"

export default {
    name: "FormView",
    components: { LoadingOverlay },
    setup () {
        const { cookies } = useCookies();
        return { cookies };
    },
    data () {
        return {
            showRandomData: false,
            randomData: '',
            formData: new FormData(),
            workingStatus: this.$t("StoreCommits.workingStatus"),
            message: this.$t('LoadingMessages.pdfLoading'),
        }
    },
    methods: {
        sendDataIntoAnnotation (manualAnnotation) {
            if (manualAnnotation === true) {
                this.manualAnnotation = true
                this.message = this.$t('LoadingMessages.pdfLoading')
            } else if (manualAnnotation === false) {
                this.manualAnnotation = false
                this.message = this.$t("LoadingMessages.downloadLoading")
            }
            const allAttributes = this.prepareDataIntoAnnotation()
            this.confirmAnnotation(allAttributes, manualAnnotation)
        },
        confirmAnnotation (allAttributes, manualAnnotation) {
            this.$store.state.showOverlay = true
            // TODO: show msg on loader 
            this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.sentForProcessing")})
            let file = this.$store.state.arrayOfDocuments
            this.formData.append('file', file)
            this.formData.append('formJSON', JSON.stringify(allAttributes))
            this.formData.append('manual', manualAnnotation)

            this.axios.post(EnvProvider.value('API_URL') + this.$root.API_POST, 
                this.formData,
                { 
                    headers: { 
                        "Content-Type": "multipart/form-data",
                        "uToken": this.cookies.get("userToken")
                    }
                }
            ).then(response => {
                this.$store.state.showOverlay = false
                // get the id of document(s) to handle/show
                // console.log(response.data.minex)
                if (response.status === 200) {
                    if (manualAnnotation === true) {
                        this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.manualAnnot")})
                    } else {
                        this.$store.commit('pushMsg', {type: 'ok', text: this.$t("StoreCommits.successfulAnnot")})
                    }
                    this.confirmSentForm(response.data, manualAnnotation)
                } else {
                    this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.processingError")})
                }
            }).catch(error => {
                console.log(error)
                this.workingStatus = this.$t("StoreCommits.processingFailed")
                alert(this.workingStatus)
            })
        },
        confirmSentForm (responseData, manualAnnotation = false) {
            if (manualAnnotation) {
                let minexData = JSON.stringify(responseData.minex)
                this.$router.push({
                    name: 'ManualAnnotationView',
                    params: {
                        documentUrl: responseData.url,
                        fileId: responseData.fileid,
                        minex: minexData
                    }
                })
            } else {
                this.$router.push({
                    name: 'DownloadView'
                })
            }
        },
        prepareDataIntoAnnotation () {
            var allAttributes = []
            var anonymizedFields = this.$root.anonymizedFields
            for (var section in anonymizedFields) {
                var sectionData = {
                    'categoryName': this.$t(anonymizedFields[section].label),
                    'content': []
                }
                const params = anonymizedFields[section].parameters
                for (var field in params) {
                    if (params[field].input !== '') {
                        var singleParameter = {
                            'inputName': this.$t(params[field].label),
                            'value': params[field].input
                        }
                        sectionData.content.push(singleParameter)
                    }
                }
                if(sectionData.content.length !== 0) {
                    allAttributes.push(sectionData)
                }
            }
            if(this.randomData || this.randomData !== '') {
                sectionData = {
                    'categoryName': this.$t("StoreCommits.anyParam"),
                    'content': []
                }
                singleParameter = {
                    'inputName': 'randomData',
                    'value': this.randomData
                }
                sectionData.content.push(singleParameter)
                allAttributes.push(sectionData)
            }
            return allAttributes
        }
    },
    created () {
        this.$store.state.showOverlay = false
        setTimeout(() => {
            var documentLink = ''
            if (this.$route.query.documentLink) {
                documentLink = decodeURIComponent(this.$route.query.documentLink)
                this.axios.get(
                    EnvProvider.value('API_URL') + this.$root.API_CRZ + '?documentLink=' + documentLink, 
                    { 
                        headers: 
                        {
                            "Content-Type": "application/octet-stream",
                        }, 
                        responseType: 'blob',
                    },
                ).then(response => {
                    if (response.status === 200) {
                        // console.log(typeof (response.data))
                        var filename = documentLink.split('/').pop()
                        var octetStreamMime = 'application/octet-stream'
                        var contentType = response.headers["content-type"] || octetStreamMime
                        var file = new File([response.data], filename, {type: contentType})
                        this.$store.commit('setDocuments', file)
                    } else {
                        this.$store.commit('pushMsg', { type: 'error', text: this.$t("StoreCommits.downloadError") })
                    }
                })
            }
      }, 200)
    }
}
</script>

<style lang="scss" src="@/assets/css/views/FormView.scss" />
<style lang="scss" src="@/assets/css/SecondaryButton.scss" />
<style lang="scss" src="@/assets/css/ConfirmButton.scss" />

